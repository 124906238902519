<template>
  <div id="app">
    <div class="box">
      <div class="footer">
        <div class="friend-link">
          <div>相关链接</div>
          <span><a href="https://www.mohurd.gov.cn/" target="_blank">住建部官网</a></span>
          <span><a href="https://jzsc.mohurd.gov.cn/home" target="_blank">全国建筑市场监管公共服务平台</a></span>
          <span><a href="http://www.cpta.com.cn/" target="_blank">中国人事考试网</a></span>
          <span><a href="http://www.mohrss.gov.cn/" target="_blank">中华人民共和国人力资源和社会保障部</a></span>

        </div>
        <div class="t">
          <div>
            <img style="width: 186px;" src="../../assets/img/logo@2x.png"  alt="LOGO" />
            <p style="color: #fff;margin-top: 15px;font-size: 16px;">客服电话：400-656-1156</p>
          </div>
          <ul>
            <li>新手指南</li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=zclc'" target="_blank">注册流程</a>
            </li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=wjmm'" target="_blank">忘记密码</a>
            </li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=gklc'" target="_blank">购课流程</a>
            </li>
          </ul>
          <ul>
            <li>支付方式</li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=yhhk'" target="_blank">银行汇款</a>
            </li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=zxzf'" target="_blank">在线支付</a>
            </li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=zfwt'" target="_blank">支付问题</a>
            </li>
          </ul>
          <ul>
            <li>特色服务</li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=zxbzr'" target="_blank">在线班主任</a>
            </li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=cdsq'" target="_blank">重读申请</a>
            </li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=tksm'" target="_blank">退款说明</a>
            </li>
          </ul>
          <ul>
            <li>常见问题</li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=kcwt'" target="_blank">课程问题</a>
            </li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=zfwt1'" target="_blank">支付问题</a>
            </li>
            <li>
              <a :href="this.url.baseUrl + '/help/help.html?id=jswt'" target="_blank">技术问题</a>
            </li>
          </ul>
          <ul style="position: relative;">
            <li>了解更多</li>
            <li @click="$router.push({ path: '/downloadapp' })">
              <a>手机学习</a>
            </li>
            
            <li class="jz">
              <div class="img">
                <img
                  :src="seat_jianzhu"
                  alt
                />
              </div>
              <a>在线咨询</a>
            </li>

            <li class="mp">
              <div class="img img2">
                <img
                  src="../../assets/img/qrcode-qxkj.jpg"
                  alt="勤学官方公众号"
                />
              </div>
              <a>关注有好礼</a>
            </li>
          </ul>
          <div class="wchat">
            <div class="b" style="margin-right: 40px;">
              <p>勤学课堂APP下载</p>
              <img
                src="https://res-qd.qinxueedu.cn/uploads/prod/20230412/86956d8b637e37f3c89303956844ef2b.png"
                 alt="勤学课堂"
              />
            </div>
            <div class="b" style="margin-right: 40px;">
              <p>微信小程序</p>
              <img
                src="https://res-qd.qinxueedu.cn/uploads/prod/20230724/6fae1f688c55ddfee42379779a8da010.jpg"
                 alt="勤学随身课堂"
              />
            </div>
          </div>
        </div>
        <div class="bot">
          <a href="https://beian.miit.gov.cn" target="_blank">版权所有：长沙勤学科技有限公司 湘ICP备2023001774号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  // name:"Foot",
  //    el: "#footer",
  data() {
    return {
      logoImg: "/assets/img/logo.png",
      codeImg: "",
      seat_jianzhu:'',
      seat_yaoshi:'',
    };
  },
  created(){
    api.service_seat().then((res) => {
      this.seat_jianzhu = res.data.data.seat_jianzhu[0].image
    }).catch((err) => {
      
    });
  },
  methods: {
    navhover(val) {
      console.log(val)
    },
    remove(val) {
      console.log(val)
    }
  },
};
</script>
<style lang="scss" scoped>
.jz,
.mp{
  cursor: pointer;
}
.mp:hover .img2{
  display: block;
}
.img2{
  top: 10px !important;
}
.jz:hover .img{
  display: block;
}
.img {
  display: none;
  width: 94px;
  height: 94px;
  box-sizing: border-box;
  padding: 3px;
  border-radius: 4px;
  background: #ffffff;
  // overflow: hidden;
  position: absolute;
  top: -25px;
  right: -22px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}
.img::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  position: absolute;
  bottom: -8px;
  left: 10px;
}
.box {
  width: 100%;
  min-width: 1250px;
  background: #363636;
}
.footer {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0 33px;
}
.footer .t {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #666666;
  justify-content: space-between;
}
.footer .t ul li {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
}
.footer .t ul li a,
.footer .t ul li {
  text-align: center;
  font-family: PingFang SC;
  color: #d9d9d9;
}
.footer .t ul li a:hover {
  text-decoration-line: underline;
}
.footer .t ul li a {
  font-size: 12px;
  font-weight: 400;
}
.footer .wchat {
  display: flex;
}
.footer .wchat .b {
  text-align: center;
}
.footer .wchat .b p {
  font-size: 14px;
  margin-bottom: 10px;
  color: #ffffff;
}
.footer .wchat .b img {
  width: 90px;
  height: 90px;
  border-radius: 5px;
}
.footer .bot {
  display: flex;
  justify-content: center;
}
.footer .bot a {
  padding-top: 25px;
  text-align: center;
  opacity: 0.8;
  font-size: 10px;
  color: #ffffff;
}
.friend-link {
  height: 100px;
  padding-bottom: 30px;
}

.friend-link:first-child div {
  padding-bottom: 10px;
  border-bottom: 1px solid #666;
  margin-bottom: 10px;
  color: white;
  font-size: 25px;
  font-weight: 700;
}

.friend-link span {
  margin-right: 25px;
}

.friend-link span a{
  color: #d9d9d9;
  font-size:14px;
}

.friend-link span a:hover {
  text-decoration:underline;
}
</style>
