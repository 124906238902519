<template>
  <el-dialog
    custom-class="addclass"
    ref="wxpay"
    title="订单超时"
    :visible.sync="classshow"
    width="700px"
    height="385px"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <div slot="title" class="wx-title">
      请选择您的报考方向
      <!-- <img @click="$emit('close')" src="../../assets/img/classclose.png" alt="" /> -->
    </div>
    <h2>建筑类</h2>
    <div class="list">
      <span
        :class="item.id == isid ? 'act' : ''"
        v-for="item in jianzhu"
        :key="item.id"
        @click="change(item)"
      >
        {{ item.name }}
      </span>
    </div>
    <div class="buton">
      <span @click="goup"> 选好了 </span>
    </div>
  </el-dialog>
</template>

<script>
import api from "../../api/api";
export default {
  name: "AddClass",
  props: {
    classshow: false,
  },
  data() {
    return {
      isid: 9,
      jianzhu: [
        {
          id: 9,
          name: "一级建造师",
        },
        {
          id: 1,
          name: "二级建造师",
        },
        {
          id: 3,
          name: "中级注册安全工程师",
        },
        {
          id: 13,
          name: "监理工程师",
        },
      ],
    };
  },
  created() {},
  methods: {
    change(e) {
      this.isid = e.id;
    },
    goup() {
      api
        .set_user_subject({
          subject_id: this.isid,
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message.success(res.data.msg);
            this.$emit("close");
          } else {
            this.$message.error(res.data.msg);
            this.$emit("close");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .addclass {
  .el-dialog__header {
    padding: 0;
  }
  .buton {
    margin: 40px 40px 20px;
    border-top: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 20px;
    span {
      width: 360px;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      background: #3e7eff;
      border-radius: 20px;
    }
  }
  .el-dialog__body {
    padding: 10px 40px;
    h2 {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-top: 30px;
    }
    .list {
      display: flex;
      .act {
        background: rgba(62, 126, 255, 0.1);
        color: #3e7eff;
        border: 1px solid rgba(62, 126, 255, 0.1);
      }
      span {
        padding: 6px 20px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        border: 1px solid #cccccc;
        border-radius: 20px;
        margin: 17px 20px 0 0;
        cursor: pointer;
      }
    }
  }
  .wx-title {
    padding: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #444444;
    border-bottom: 2px solid #e2e2e2;
    font-size: 22px;
    img {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 27px;
      right: 20px;
      cursor: pointer;
    }
  }
}
</style>