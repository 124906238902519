/****   request.js   ****/
// 导入axios
import axios from "axios";
import qs from "qs";
import Cookies from 'js-cookie'
import router from '@/router'
// 使用element-ui Message做消息提醒
import { Message } from "element-ui";
// var callback = {
//     errorHandler: {
//       status_401: (output) => {
//         output("未授权，请重新登录");

//       },
//     },
//   };
var errorHandler = {}
var defaultErrorHandler = {
    "status_400": (output) => {
        output("错误请求");
    },
    "status_401": (output) => {
        output("未授权，请重新登录!");
        localStorage.removeItem("token")
        router.push({ name: "Login" })
    },
    "status_403": (output) => {
        output("拒绝访问");
    },
    "status_404": (output) => {
        output("请求错误,未找到该资源");
        // window.location.href = "/NotFound";
    },
    "status_405": (output) => {
        output("请求方法未允许");
    },
    "status_408": (output) => {
        output("请求超时");
    },
    "status_500": (output) => {
        output("服务器端出错");
    },
    "status_501": (output) => {
        output("网络未实现");
    },
    "status_502": (output) => {
        output("网络错误");
    },
    "status_503": (output) => {
        output("服务不可用");
    },
    "status_504": (output) => {
        output("网络超时");
    },
    "status_505": (output) => {
        output("http版本不支持该请求");
    },
    "status_default": (output) => {
        output(`连接错误${error.response.status}`);
    },
}
//1. 创建新的axios实例，
const service = axios.create({
    // 公共接口--
    baseURL: process.env.BASE_API,
    // 超时时间 单位是ms，这里设置了3s的超时时间
    timeout: 10 * 1000,
});
// 2.请求拦截器
service.interceptors.request.use(
    (config) => {
        //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
        // config.data = qs.stringify(config.data); //数据转化,也可以使用qs转换
        config.headers = {
            "Content-Type": "application/x-www-form-urlencoded", //配置请求头
            token: localStorage.getItem("token")
        };
        if (typeof (config.callback) == 'object') {
            var callback = config.callback;
            if (typeof (callback.errorHandler) == 'object') {
                // 用自定义回调覆盖默认处理方法
                errorHandler = Object.assign(defaultErrorHandler, callback.errorHandler);
            }
        }
        //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
        // const token = sessionStorage.getItem("token") //这里取token之前，你肯定需要先拿到token,存一下
        // if (token) {
        //     config.params = { token: token }; //如果要求携带在参数中
        //     config.headers.token = token; //如果要求携带在请求头中
        // }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// 3.响应拦截器
service.interceptors.response.use(
    (response) => {
        //接收到响应数据并成功后的一些共有的处理，关闭loading等
        // console.log(response)
        return response;
        // let a = JSON.stringify(response).replaceAll('http://liyoujiaoyu.oss-cn-zhangjiakou.aliyuncs.com/', 'https://ufile.liyouedu.cn/').replaceAll('http://ufile.liyouedu.cn/', 'https://ufile.liyouedu.cn/')
        // // console.log(a)
        // return JSON.parse(a);
    },
    (error) => {
        /***** 接收到异常响应的处理开始 *****/
        console.log(error)
        if (error && error.response) {
            // 1.公共错误处理
            // 2.根据响应码具体处理

            var handler = errorHandler["status_" + error.response.status];

            if (!errorHandler["status_" + error.response.status]) {

                handler = errorHandler.status_default;
            }
            handler(Message.error);
            // switch (error.response.status) {
            //     case 400:
            //         error.message = "错误请求";
            //         break;
            //     case 401:
            //         error.message = "未授权，请重新登录";
            //         break;
            //     case 403:
            //         error.message = "拒绝访问";
            //         break;
            //     case 404:
            //         error.message = "请求错误,未找到该资源";
            //         window.location.href = "/NotFound";
            //         break;
            //     case 405:
            //         error.message = "请求方法未允许";
            //         break;
            //     case 408:
            //         error.message = "请求超时";
            //         break;
            //     case 500:
            //         error.message = "服务器端出错";
            //         break;
            //     case 501:
            //         error.message = "网络未实现";
            //         break;
            //     case 502:
            //         error.message = "网络错误";
            //         break;
            //     case 503:
            //         error.message = "服务不可用";
            //         break;
            //     case 504:
            //         error.message = "网络超时";
            //         break;
            //     case 505:
            //         error.message = "http版本不支持该请求";
            //         break;
            //     default:
            //         error.message = `连接错误${error.response.status}`;
            // }
        } else {
            // 超时处理
            if (JSON.stringify(error).includes("timeout")) {
                Message.error("服务器响应超时，请刷新当前页");
            } else {
                Message.error("连接服务器失败");

            }
        }

        /***** 处理结束 *****/
        //如果不需要错误处理，以上的处理过程都可省略
        return Promise.resolve(error.response);
    }
);
//4.导入文件
export default service;