import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import element from './element/index'
import Tabs from './components/tabs/nav'
import Foot from './components/foot/foot'
import SlideNav from './components/sideNav.vue'
import Noclass from './components/noClass.vue'
import Url from './env/env'
import md5 from 'js-md5';
import qs from 'qs'
import cookie from 'vue-cookie'
import menu from "./components/menu.vue"
import notice from "./components/notice.vue"
import code from './components/code.vue'
import axios from 'axios'
Vue.prototype.$axios = axios
// import preview from 'vue-photo-preview'
// import 'vue-photo-preview/dist/skin.css'
// Vue.use(preview)
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

//Vue.use(Viewer) 默认配置写法
Vue.use(Viewer, {
    defaultOptions: {
        zIndex: 9999
    }
})

// import $ from jquery
Vue.component("Noclass", Noclass);
Vue.component("Slide", SlideNav);
Vue.component("Notice", notice);
Vue.component("Menu", menu);
Vue.component("Code", code);
Vue.prototype.bus = new Vue;
Vue.prototype.$cookies = cookie;
Vue.prototype.qs = qs;
Vue.prototype.$md5 = md5;
Vue.prototype.url = Url
Vue.use(Tabs);
Vue.use(Foot);
Vue.use(element)


// 样式文件

// Vue.use(Button)
Vue.config.productionTip = false

// 设置eventbus传值
window.eventBus = new Vue()

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')