import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  // state是自定义的一些变量,需要用来保存数据
  state: {
    user_answer: [], // 存储用户答案
    paper_info: [],  // 交卷成功存储成绩单数据
    pathName: "",
    currDbSource: {},
    currJobData: {},
    DbSource: [],
    paper: [],
    shop_detail: '',
    order_detail: '',
    payinfo:'',
  },
  // mutations是用来触发事件，相当于方法
  mutations: {
    //订单
    OrderDetail(state, detail) {
      state.order_detail = detail
    },
    Payinfo(state, payinfo) {
      state.payinfo = payinfo
    },
    // 商城
    ShopDetail(state, detail) {
      state.shop_detail = detail
    },
    // 用户试卷相关
    PaperState(state, paper) {
      state.paper = paper
    },
    // 保存用户答案
    saveUserAnswer(state, userAnswer) {
      state.user_answer = userAnswer
    },
    // 交卷成功存储成绩单数据
    savepaperInfo(state, paperInfo) {
      state.paper_info = paperInfo
    },
    // 保存当前菜单栏的路径
    savePath(state, pathName) {
      state.pathName = pathName;
    },
    // 保存当前点击的数据源
    saveCurrDbSource(state, currDbSource) {
      state.currDbSource = currDbSource;
    },
    // 保存当前点击的元数据
    saveCurrJobData(state, currJobData) {
      state.currJobData = currJobData;
    },
    // 保存所有数据源
    saveDbSource(state, DbSource) {
      state.DbSource = DbSource;
    }

  },
  actions: {},
  modules: {}
})