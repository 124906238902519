<template>
  <div id="app">
    <!-- 通知 -->
    <div class="notice_box">
      <div class="notice_content">
        <div><img src="../assets/img/notice.png" alt="" /></div>
        <div class="notice">
          <div
            class="contlist"
            ref="contlist"
            @mouseover="stopScroll"
            @mouseout="startScroll"
          >
            <div style="padding-left:20px">
              {{ notice_list }}
            </div>
          </div>
        </div>
        <!-- <div>
          章节练习因定期调整和更新，题量会发生变化，试题总量，请考生以是几能抽出对题量为准。
        </div> -->
        <!-- <marquee class="scroll" behavior="behavior" width="800" loop="2"
          >章节练习因定期调整和更新，题量会发生变化，试题总量，请考生以是几能抽出对题量为准。</marquee
        > -->
        <!-- 通知 -->
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      notice_list: "", // 题库公告
      scrollW: 0, //记录滚动到哪了
    };
  },

  mounted() {
    //实例挂载完毕前
    //调用滚动代码
    this.scroll();
  },
  created() {
    this.getNotice();
  },
  methods: {
    // 获取公告数据
    getNotice() {
      var data = {
        page: 1,
        page_size: 1,
        category: "ex",
      };
      api.topic_notice(data).then((res) => {
        this.notice_list = res.data.data.list[0].content;
        console.log("题库公告", res);
      });
    },
    //鼠标悬停，停止滚动
    stopScroll() {
      var target = this.$refs.contlist;
      clearInterval(this.scrollTime);
    },
    //鼠标移开 ，接着滚动
    startScroll() {
      var target = this.$refs.contlist;
      this.scrollW = target.offsetLeft; // 移开时记录向左移动的距离
      this.scroll();
    },
    //循环滚动
    scroll() {
      var that = this; //因为定时器里要使用vue对象时不能用this, 在定时器中的 this 代表
      var target = this.$refs.contlist;
      var initLeft = 0;
      if (this.scrollW < 0) {
        initLeft = this.scrollW * -1;
      }
      //定时器
      this.scrollTime = setInterval(function() {
        initLeft++;
        if (initLeft >= target.offsetWidth / 2) {
          initLeft = 0;
        }
        target.style.left = "-" + initLeft + "px"; //获取不到translateX的值改用 left
      }, 20);
    },
  },
};
</script>

<style lang="scss" scoped>
/* 通知 */

// 通知
.notice_box {
  display: flex;
  width: 100%;
  height: 50px;
  background: #e9f2ff;
  margin-top: -3px;
  display: flex;
  // align-items: center;
  .notice_content {
    position: relative;
        height: 50px;
    // top: 50%;
    width: 1200px;
    height: 50px;
    margin: auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    div {
      width: 48px;
      height: 50px;
      overflow: hidden;
      z-index: 1;
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 16px;
        margin: 0 10px 0 20px;
      }
    }
    .notice {
      width: 814px;
      display: flex;
      align-items: center;
      position: relative;
      .contlist {
        position: absolute;
        /* top: 1px; */
        width: 814px;
        /* height: 22px; */
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        display: flex;
        div {
          width: 814px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #3e7eff;
          line-height: 50px;
        }
      }
    }
  }
}
</style>
