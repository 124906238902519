import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
// 禁止使用字段  pc proxy
Vue.use(VueRouter);
const routes = [
    // 404
    // {
    //     path: "/404",
    //     name: "404",
    //     // redirect: '/index',
    //     component: () =>
    //         import("../views/404/index.vue"),
    // },
    // {
    //     path: '*', redirect: '/', hidden: true
    // },
    // 首页
    {
        path: "/",
        name: "Index",
        // redirect: '/index',
        component: () =>
            import(/* webpackChunkName: "Index" */ "../views/index/index.vue"),
    },
    // 课程展示
    {
        path: "/course/:is_pageid",
        name: "Course",
        component: () =>
            import(/* webpackChunkName: "Course" */ "../views/showCourse/index.vue"),
    },
    {
        path: "/shopdetail/:course_category_id/:class_type_id",
        name: "Shopdetail",
        component: () =>
            import(/* webpackChunkName: "Course" */ "../views/shopdetail/index.vue"),
    },
    //订单确认
    {
        path: "/order/order_step1",
        name: "Order_step1",
        component: () =>
            import(/* webpackChunkName: "Course" */ "../views/order/order_step1.vue"),
    },
    {
        path: "/order/order_step2/:order_sn",
        name: "Order_step2",
        component: () =>
            import(/* webpackChunkName: "Course" */ "../views/order/order_step2.vue"),
    },
    {
        path: "/order/order_step3",
        name: "Order_step3",
        component: () =>
            import(/* webpackChunkName: "Course" */ "../views/order/order_step3.vue"),
    },
    {
        path: "/order/alipay",
        name: "Alipay",
        component: () =>
            import(/* webpackChunkName: "Course" */ "../views/order/alipay.vue"),
    },
    //模考大赛
    {
        path: "/modelpaper",
        name: "ModelPaper",
        component: () =>
            import(/* webpackChunkName: "ModelPaper" */ "../views/modelpaper/index.vue"),
    },
    {
        path: "/modelpaper/detail/:is_pageid/:chapter_id/:chapter_name/:paper_id/:paper_name/:router_name",
        name: "modelPaperdetail",
        component: () =>
            import(/* webpackChunkName: "modelPaperdetail" */ "../views/modelpaper/detail/index.vue"),
    },
    // 试听课程
    {
        path: "/audition/:is_pageid",
        name: "Audition",
        component: () =>
            import(/* webpackChunkName: "Audition" */ "../views/auditionClass/index.vue"),
    },
    // 试听课程详情页
    {
        path: "/auditionInfo/:is_pageid/:info_id",
        name: "AuditionInfo",
        component: () =>
            import(/* webpackChunkName: "AuditionInfo" */ "../views/auditionClass/auditionInfo/index.vue"),
    },
    // 试听课程详情页手机端
    {
        // path: '/phone/:is_pageid/:info_id',
        path: "/phone/:info_id/:two_nav",
        name: "Phone",
        component: () =>
            import(/* webpackChunkName: "Phone" */ "../views/phone/index.vue"),
    },
    // 师资力量
    {
        path: "/teachers/:is_pageid",
        name: "Teachers",
        component: () =>
            import(/* webpackChunkName: "Teachers" */ "../views/teachers/index.vue"),
    },
    // 资讯中心
    {
        path: "/information/:is_pageid",
        name: "Information",
        component: () =>
            import(/* webpackChunkName: "Information" */ "../views/information/index.vue"),
    },
    // 资讯中心详情页
    {
        path: "/info/:is_pageid/:info_id", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: "Info",
        component: () =>
            import(/* webpackChunkName: "Info" */ "../views/information/informationInfo/index.vue"),
    },
    // 关于我们
    {
        path: '/about',
        name: 'About',
        component: () =>
            import(/* webpackChunkName: "About" */ '../views/about/index.vue')
    },

    // 咨询报名
    // {
    //     path: "/enroll/:is_pageid",
    //     name: "Enroll",
    //     component: () =>
    //         import ("../views/enroll/index.vue"),
    // },
    // 个人中心
    {
        path: "/personal/:is_pageid",
        name: "Personal",
        component: () =>
            import(/* webpackChunkName: "Personal" */ "../views/personal/index.vue"),
    },
    // 个人中心课程详情页
    {
        path: "/myClass/:is_pageid/:course_id/:coursecate_id",
        name: "MyClass",
        component: () =>
            import(/* webpackChunkName: "MyClass" */ "../views/personal/myClass/index.vue"),
    },
    // 登录
    {
        path: "/login",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "Login" */ "../views/login/index.vue"),
    },
    // 注册
    {
        path: "/register",
        name: "Register",

        component: () =>
            import(/* webpackChunkName: "Register" */ "../views/register/index.vue"),
    },
    // 忘记密码
    {
        path: "/forgetPwd",
        name: "ForgetPwd",

        component: () =>
            import(/* webpackChunkName: "ForgetPwd" */ "../views/forgetPwd/index.vue"),
    },
    // 章节练习
    {
        path: "/chapter/:is_pageid/:select_id",
        name: "Chapter",
        component: () =>
            import(/* webpackChunkName: "Chapter" */ "../views/topic/chapter/index.vue"),
    },
    // 章节练习答题页
    {
        // :chapter_category/
        path: "/optionTopic/:is_pageid/:select_id/:chapter_id",
        name: "OptionTopic",
        component: () =>
            import(/* webpackChunkName: "OptionTopic" */ "../views/topic/chapter/optionTopic/index.vue"),
    },
    // 每日一练
    {
        // :chapter_category/
        path: "/everyday/:is_pageid/:select_id",
        name: "Everyday",
        component: () =>
            import(/* webpackChunkName: "Everyday" */ "../views/topic/everyday/index.vue"),
    },
    // 每日一练 开始练习页面
    {
        // :chapter_category/
        path: "/practice/:is_pageid/:chapter_id/:three_chaptercate_id",
        name: "Practice",
        component: () =>
            import(/* webpackChunkName: "Practice" */ "../views/topic/everyday/practice/index.vue"),
    },
    // 历年真题
    {
        // :chapter_category/
        path: "/trueTopic/:is_pageid/:select_id",
        name: "TrueTopic",
        component: () =>
            import(/* webpackChunkName: "TrueTopic" */ "../views/topic/trueTopic/index.vue"),
    },
    // 历年真题 试卷介绍页
    {
        // :chapter_category/
        path: "/introduce/:is_pageid/:chapter_id/:chapter_name/:paper_id/:paper_name/:router_name",
        name: "Introduce",
        component: () =>
            import(/* webpackChunkName: "Introduce" */ "../views/topic/trueTopic/introduce/index.vue"),
    },
    // 历年真题 答题页
    {
        // :chapter_category/
        path: "/answer/:is_pageid/:chapter_id/:paper_id/:paper_url/:is_finish/:router_name",
        name: "Answer",
        component: () =>
            import(/* webpackChunkName: "Answer" */ "../views/topic/trueTopic/answer/index.vue"),
    },
    // 历年真题 成绩单
    {
        // :chapter_category/
        path: "/result/:is_pageid/:chapter_id/:paper_id",
        name: "Result",
        component: () =>
            import(/* webpackChunkName: "Result" */ "../views/topic/trueTopic/result/index.vue"),
    },
    // 模拟试题
    {
        // :chapter_category/
        path: "/simulation/:is_pageid/:select_id",
        name: "Simulation",
        component: () =>
            import(/* webpackChunkName: "simulation" */ "../views/topic/simulation/index.vue"),
    },
    // 做题记录
    {
        // :chapter_category/
        path: "/record/:is_pageid/:select_id",
        name: "Record",
        component: () =>
            import(/* webpackChunkName: "Record" */ "../views/topic/record/index.vue"),
    },
    // 做题记录 详情页
    {
        // :chapter_category/
        path: "/details/:is_pageid/:chapter_id/:chapter_name/:id/:name",
        name: "Details",
        component: () =>
            import(/* webpackChunkName: "Details" */ "../views/topic/record/details/index.vue"),
    },
    //交卷之后 / 做题记录 成绩单
    {
        // :chapter_category/
        path: "/reportCard/:is_pageid/:chapter_id/:chapter_name/:id/:is_handpaper/:router_name",
        name: "ReportCard",
        component: () =>
            import(/* webpackChunkName: "ReportCard" */ "../views/topic/record/details/reportCard/index.vue"),
    },
    // // 做题记录 成绩单页
    // {
    //     // :chapter_category/
    //     // /:name
    //     path: "/inventory/:is_pageid/:select_id:paper_id",
    //     name: "Inventory",
    //     component: () =>
    //         import("../views/topic/record/details/reportCard/index.vue"),
    // },
    // 错题本
    {
        // :chapter_category/
        path: "/wrongQuestion/:is_pageid/:select_id",
        name: "WrongQuestion",
        component: () =>
            import(/* webpackChunkName: "WrongQuestion" */ "../views/topic/wrongQuestion/index.vue"),
    },
    // 错题本 查看解析
    // {
    //     // :chapter_category/
    //     path: "/wrongAnalysis/:is_pageid/:chapter_id/:qtype/:name",
    //     name: "WrongAnalysis",
    //     component: () =>
    //         import ("../views/topic/collect/analysis/index.vue"),
    // },
    {
        // :chapter_category/
        path: "/wrongAnalysis/:is_pageid/:chapter_id/:qtype/:name",
        name: "WrongAnalysis",
        component: () =>
            import(/* webpackChunkName: "WrongAnalysis" */ "../views/topic/wrongQuestion/wrongAnalysis/index.vue"),
    },
    // 试题收藏
    {
        // :chapter_category/
        path: "/collect/:is_pageid/:select_id",
        name: "Collect",
        component: () =>
            import(/* webpackChunkName: "Collect" */ "../views/topic/collect/index.vue"),
    },
    // 试题收藏 重新做题
    {
        // :chapter_category/
        path: "/again/:is_pageid/:chapter_id/:qtype/:name/:error_collect",
        name: "Again",
        component: () =>
            import(/* webpackChunkName: "Again" */ "../views/topic/collect/again/index.vue"),
    },
    // 试题收藏查看解析
    {
        // :chapter_category/
        path: "/analysis/:is_pageid/:chapter_id/:qtype/:name/:wrong_collect",
        name: "Analysis",
        component: () =>
            import(/* webpackChunkName: "Analysis" */ "../views/topic/collect/analysis/index.vue"),
    },
    // 全部解析页面
    {
        path: "/whole/:is_pageid/:chapter_id/:url/:id/:type_id",
        name: "Whole",
        component: () =>
            import(/* webpackChunkName: "Whole" */ "../views/topic/analysis/whole/index.vue"),
    },
    // 错题解析页面
    {
        path: "/wrong/:is_pageid/:chapter_id/:url/:id/:type_id",
        name: "Wrong",
        component: () =>
            import(/* webpackChunkName: "Wrong" */ "../views/topic/analysis/wrong/index.vue"),
    },
    // APP下载
    {
        path: "/downloadapp",
        name: "Downloadapp",
        component: () =>
            import(/* webpackChunkName: "Downloadapp" */ "../views/downloadapp/index.vue"),
    },
    // 关注公众号
    {
        path: "/servewx",
        name: "Servewx",
        component: () =>
            import(/* webpackChunkName: "Servewx" */ "../views/servewx/index.vue"),
    },
    // 直播列表
    {
        path: "/livelist/:is_pageid",
        name: "Livelist",
        component: () =>
            import(/* webpackChunkName: "Servewx" */ "../views/livelist/index.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    //解决跳转后位置不在顶部
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});
export default router;