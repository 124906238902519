<template>
  <div id="app">
    <div class="no_class_box" v-if="loading == true">
      <img src="../assets/img/wukemu.png" alt="" />
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  props: {},
  name: "noclass",
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  beforeCreate() {},
  created() {
    this.bus.$on("noclass", function (data) {
      this.loading = !!data;
    });
  },
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
.no_class_box {
  width: 1200px;
  height: 500px;
}
</style>
