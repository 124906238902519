

let a = {
    ////正式环境
    baseUrl: "https://www.qinxuejiaoyu.com",
    imgUrl: "https://www.qinxuejiaoyu.com",
    apiUrl: "https://api.qinxuejiaoyu.com",
    wapUrl: "https://m.qinxuejiaoyu.com"
}
let b = {
    ////测试
    baseUrl: "https://www.qinxuejiaoyu.com",
    imgUrl: "https://www.qinxuejiaoyu.com",
    apiUrl: "https://api.qinxuejiaoyu.com",
    wapUrl: "https://m.qinxuejiaoyu.com"
}
// console.log(process.env)
let c = process.env.VUE_APP_TITLE === 'development' ? b : a
export default c;